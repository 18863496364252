import type { Ref } from 'vue';
import { useState } from '#imports';

type ActiveShowcaseViewType = 'services' | 'countries';
type useChangeShowcaseViewType = {
  activeShowcaseView: Ref<ActiveShowcaseViewType>;
  changeActiveShowcase: (active: ActiveShowcaseViewType) => void
}

export function useChangeShowcaseView (): useChangeShowcaseViewType {
  const activeShowcaseView = useState<ActiveShowcaseViewType>('activeShowcaseView', () => 'countries');

  function changeActiveShowcase (active: ActiveShowcaseViewType) {
    activeShowcaseView.value = active;
  }

  return {
    activeShowcaseView,
    changeActiveShowcase
  };
}
